define("incus-vastaanota/locales/fi/translations", ["exports"], function (exports) {
  exports["default"] = {
    common: {
      example: "Esimerkki",
      cancel: "Keskeytä",
      archive: "Arkisto",
      traffic: "Liikenne",
      webshop: "Kauppa",
      back: "Takaisin",
      error: "Virhe",
      ready: "Valmis",
      save: "Tallenna",
      download: "Lataa",
      deleteInvoice: "Tuhoa lasku",
      toChecker: "Tarkastajalle",
      toAccounting: "Kirjanpitäjälle",
      toCustomEmail: "Lähettäjälle",
      addAttachment: "Lisää liite",
      logout: "Kirjaudu ulos",
      list: "Ostolaskut",
      timeStamp: "Aika",
      createInvoice: "Ostolasku",
      createSalesInvoice: "Myyntilasku",
      uploadComplete: "Lataus valmis",
      settings: "Asetukset",
      tools: "Työkalut",
      addresses: "Osoitteet",
      validationFailed: "Lomakkeella on virheitä",
      notImplemented: "Ominaisuus ei ole vielä saatavilla",
      pcs: "kpl",
      show: "Näytä",
      clear: "Tyhjennä",
      terms: "Palveluehdot",
      user: "Käyttäjä",
      pricing: "Hinnasto",
      username: "Käyttäjätunnus"
    },
    archive: {
      archives: 'Arkistot',
      clearAllFilters: 'Nollaa kentät',
      found: 'Löytyi',
      freeText: 'Vapaa teksti',
      loading: 'Ladataan arkistoja...',
      received: 'Vastaanotetut',
      search: 'Etsi',
      sent: 'Lähetetyt',
      collect: 'PERI',
      sendToCollector: 'Lähetä perintään',
      homepage: 'Kotisivut',
      collectInvoices: 'Peri laskuja',
      selectedInvoices: 'Valitut laskut',
      collector: 'Perijä'
    },

    traffic: {
      sent: "Lähetetyt",
      received: "Vastaanotetut",
      results: "hakutulosta",
      pitstop: {
        pitstop: "Käsittelyssä",
        invoiceNumber: "Laskun numero",
        emailOrPhone: "Sähköposti tai puhelinnumero",
        receiverEmailHeading: "Syötä ostajan sähköpostiosoite",
        receiverEmail: "Ostajan sähköposti",
        signerName: "Nimenkirjoituksellisen henkilön nimi",
        signerEmail: "Nimenkirjoituksellisen henkilön sähköposti",
        cashOut: "Muuta laskut rahaksi!",

        pitstopStatus: {
          waiting: "Odottaa käsittelyä",
          send_to_factoring: "Lähetetään rahoitukseen",
          waiting_signedtime: "waiting_signedtime",
          waiting_financieragreetime: "waiting_financieragreetime",
          waiting_buyeracceptance: "waiting_buyeracceptance"
        }
      },
      tables: {
        sentTime: "Lähetyspäivä",
        exitTime: "Lähetyspäivä",
        receivedDate: "Vastaanottopäivä",
        picture: "Kuva",
        data: "Data/XML",
        documentId: "Dokumentin ID",
        invoiceNumber: "Dokumentin ID",
        receiver: "Vastaanottaja",
        recipient: "Vastaanottaja",
        sender: "Lähettäjä",
        messageType: "Sanomatyyppi",
        dueDate: "Eräpäivä",
        deliveryMethod: "Toimitustapa",
        attachments: "Liitteet",
        acknowledgment: "Kuittaus",
        ackStatus: "Kuittaus",
        createdDate: "Lähetyspäivä",
        deliveryDate: "Vastaanottopäivä",
        documentDate: "Dokumentin päivä",
        bankAccount: "Pankkitili",
        sellerReference: "Myyjän viite",
        orderReference: "Tilauksen viite",
        paymentReference: "Pankkiviite",
        paymentStatus: "Maksun tila",
        transferStatus: "Siirron tila",
        approvalStatus: "Hyväksynnän tila",
        pitstopStatus: "Tila",
        pitstopDelete: "Poista",
        pitstopDeleteConfirmation: "Haluatko varmasti poistaa laskun",
        pitstopTimeleft: "Jäljellä",
        expiresDate: "Lähetetään",
        provision: "Provisio",
        sum: "Summa",
        sumWithoutProvision: "Tiliöitävä summa",
        email: "Sähköposti"
      },

      messageTypes: {
        bill: "Lasku",
        billOrSomething: "Lasku tai muu",
        acknowledgment: "Kuittaus",
        sticker: "Tarra",
        billOrPayslip: "Lasku tai palkkalaskelma",
        factoring: "Rahoituskopio",
        edi: "EDI",
        unknown: "Tuntematon"
      },

      deliveryMethods: {
        webBill: "Verkkolasku",
        letter: "Kirje",
        edi: "EDI",
        email: "Sähköposti",
        eBill: "e-lasku",
        directPayment: "Suoramaksu",
        webPost: "Netposti",
        scanned: "Skannattu",
        unknown: "Tuntematon"
      },

      "true": "Kyllä",
      "false": "Ei"
    },
    session: {
      loginTitle: "Kirjaudu",
      emailPlaceholder: "Sähköposti",
      passwordPlaceholder: "Salasana",
      loginAction: "Kirjaudu sisään",
      logoutAction: "Kirjaudu ulos",
      forgotPassword: "Unohtuiko salasana?",
      loginError: "Väärä tunnus/salasana",
      logoutSuccess: "Olet kirjautunut ulos.",
      registration: "Ota käyttöön",
      hallinta: "Hallinta.apix.fi:n toiminnot ovat siirtyneet Laskumappiin työkalujen alle",
      webshop: "Webshop.apix.fi:n toiminnot ovat siirtyneet Laskumappiin"
    },
    settings: {
      contracts: "Palvelut",
      viewTitle: "Asetukset",
      language: "Kieli",
      finnish: "Suomi",
      swedish: "Svenska",
      english: "English",
      notification: "Sähköpostiosoitteet ilmoituksille",
      notificationsSaved: "Talletettu",
      arrivalNotification: "Ilmoitus saapuneista laskuista",
      dueDateNotification: "Ilmoitus lähestyvästä eräpäivästä (eräpäivä 3 päivän sisällä)",
      emailAddress: "Sähköposti",
      saveNotificationSettings: "Tallenna",
      invoiceSending: "Ostolaskujen lähetyspalvelu aktiivinen",
      noInvoiceSending: "Ei ostolaskujen lähetystä",
      selectServices: "Valitse palvelut",
      oauth: {
        name: "Integraatio",
        added: "tunnukset on liitetty Apixin palveluun",
        notadded: "tunnuksia ei ole liitetty Apixin palveluun",
        add: "Liitä palvelu",
        remove: "Poista liitos"
      },
      countryname: "Maa",
      country: {
        finland: "Suomi",
        sweden: "Ruotsi"
      },
      userInfo: {
        title: "Omat tiedot myyntilaskupohjalle",
        userInfo: "Käyttäjän tiedot",
        name: "Nimi",
        additionalName: "Aputoiminimi",
        country: "Maa",
        businessID: "Y-tunnus",
        address: "Osoite",
        accounts: "Tilit",
        city: "Postitoimipaikka",
        zip: "Postinumero",
        IBAN: "IBAN",
        contactPerson: "Yhteyshenkilö",
        phone: "Puhelinnumero",
        www: "Verkkosivu",
        email: "Sähköpostiosoite",
        fax: "Faksi",
        paymentCondition: "Maksuehto pv netto",
        bankAccount: "Tilinumero",
        type: "Tilityyppi",
        penaltyInterest: "Viivästyskorko%",
        invoiceLogo: "Laskun logo",
        logoSuccess: "Logo tallennettu",
        logoDeleted: "Logo poistettu",
        deleteLogo: "Poista logo",
        saveLogo: "Tallenna logo",
        invalidLogo: "Tiedoston tulee olla jpeg tai png muodossa ja alle 300kt",
        eInvoiceAddress: "Lähettäjän verkkolaskuosoite",
        oldPass: "Vanha salasana",
        newPass: "Uusi salasana",
        repeatPass: "Toista uusi salasana",
        billingAddress: "Laskutus",
        officialAddress: "Virallinen",
        visitingAddress: "Käynti",
        save: "Tallenna",
        changePassword: "Vaihda salasana",
        addresses: "Osoitteet",
        addressType: "Osoitetyyppi",
        changePass: "Vaihda salasana",
        passwordRequimentTitle: "Uuden salasanan vaatimukset",
        passwordRequiment: "Salasanan tulee olla vähintään kaksitoista (12) merkkiä pitkä ja siinä tulee olla vähintään yksi pieni kirjain, iso kirjain, numero ja erikoismerkki.",
        updatedPassword: "Salasana vaihdettu",
        updatePasswordFailed: "Salasanan vaihto epäonnistui",
        updateWrongPass: "Vanha salasana väärin",
        updatePasswordsNomatch: "Uudet salasanat eivät täsmää",
        updatedUser: "Käyttäjätiedot tallennettu",
        updateUserFailed: "Käyttäjätietojen tallennus epäonnistui"
      },
      LowellSEDebtCollection: {
        header: "Lowell Debt Collection service",
        sendRequest: "Request Lowell Debt Collection service",
        status: {
          WAITING: "Waiting Lowell's approval",
          READY: "Lowell Debt Collection service is active",
          UNKNOWN: "Lowell Debt Collection state is unknown"
        }
      }
    },
    tools: {
      admin: "Hallinta",
      addresses: {
        company: "Yritys",
        name: "Nimi",
        eInvoiceAddress: "Verkkolaskuosoite",
        operator: "Operaattori",
        otherOperator: "Muu operaattori",
        Ytunnus: "Y-tunnus",
        add: "Lähetä",
        addedNewAddress: "Ilmoitus lähetetty",
        addNewAddress: 'Jos osoite ei ole käytössä, voit ilmoittaa sen <a class="instructions-link" href="https://apixmessaging.zendesk.com/hc/fi">tästä</a>',
        checkAddress: "Tarkista onko verkkolaskuosoite käytössä",
        addressQuery: "Hae Y-tunnuksella yrityksen verkkolaskuosoitteet",
        test: "Tarkista",
        query: "Hae",
        feedbackNeg: "Osoite ei ole käytössä",
        feedbackPos: "Osoite on käytössä",
        emailNotificationOne: "Haluan ilmoituksen",
        emailNotificationTwo: "lisäyksestä sähköpostiini",
        emailToNotificate: "Sähköposti",
        commentField: "Lisätietoja"
      },
      users: {
        title: "Käyttäjät",
        usernameInUse: "Käyttäjätunnus on jo käytössä",
        username: "Tunnus",
        password: "Salasana",
        newPass: "Uusi salasana",
        add: "Lisää",
        save: "Tallenna",
        "delete": "Poista",
        edit: "Muokkaa",
        cancel: "Peru",
        allowSell: "Salli myyntilaskujen teko",
        allowPurchase: "Salli ostolaskujen teko",
        newUser: "Uusi käyttäjä"
      }
    },
    listview: {
      barcodePrompt: "Kopioi tästä viivakoodi",
      totals: "Yhteensä",
      due0To3: "0-3 pvä",
      due4To7: "4-7 pvä",
      due8OrMore: "8+ pvä",
      paid: "Maksettu",
      unpaid: "Maksamatta",
      viewTitle: "Ostolaskut",
      noneSelected: "Valitse ensin lasku(t)",
      nameFilter: "Suodata nimellä",
      amountToPayFilter: "Suodata loppusummalla",
      detailView: "Info",
      documentDateFilter: "Etsi laskun päivällä",
      startDate: "Alkaen",
      endDate: "Päättyen",
      dueDateFilter: "Etsi eräpäivällä",
      startDueDate: "Alkaen",
      endDueDate: "Päättyen",
      clearAllDateFilters: "Tyhjennä päivämäärät",
      approvalStatus: "Tarkastus",
      approverEmail: "Tarkastajan sähköposti",
      approverTimeStamp: "Lähetetty",
      approverComment: "Kommentti",
      approverApprovalStatus: "Hyväksyntä",
      noComments: "Ei vielä kommentoitu",
      paymentStatusFilter: "Suodata maksun tilalla"
    },
    createSalesView: {
      viewTitle: "Luo myyntilasku",
      uploadTitle: "Lataa",
      Upload: "Lähetä laskut",
      tooManyInvoices: "Liikaa laskuja yhdessä tiedostossa",
      max: "maksimi",

      tables: {
        fileName: "Tiedostonimi",
        fileSize: "Koko",
        fileType: "Tyyppi",
        Status: "Tila",
        Response: "",
        Amount: "Määrä"
      },

      status: {
        notsent: "",
        OK: "Lähetetty onnistuneesti",
        ERR: "Virhe lähetyksessä"
      },

      response: {
        Saldo: "Saldoa jäljellä",
        CostInCredits: "Lähetyksen hinta",
        AcceptedDocument: "Lähetettyjä laskuja",
        NetworkedInvoices: "Verkkolaskuja",
        Letters: "Kirjeitä"
      }
    },
    createPurchaseView: {
      viewTitle: "Luo ostolasku"
    },
    createView: {
      selectedImage: "Valittu kuva",
      barcodeInvalid: "Viivakoodi ei kelpaa",
      basicInformation: "Perustiedot",
      paymentRecipient: "Maksun saaja",
      bicCode: "BIC",
      products: "Tuotteet",
      taxFree: "Yhteensä (veroton)",
      taxAmount: "Veron määrä",
      total: "Yhteensä",
      taxBase: "Verokanta",
      imageField: "Lasku PDF",
      notFilled: "Täytä kenttä ",
      totalZero: "Laskun summa on nolla (ei tuotteita).",
      viewTitle: "Luo lasku",
      pleaseUpload: "Lataa laskun kuva PDF-muodossa",
      sendToScanning: "Lähetä PDF skannauspalveluun",
      createSellInvoice: "Syötä laskun tiedot käsin",
      createBuyInvoice: "Syötä laskun tiedot käsin",
      fileUploaded: "Tiedosto siirretty, lähetä PDF skannaukseen tai syötä tiedot.",
      invalidIBAN: "Pankkitilin pitää olla IBAN muodossa",
      invalidField: "on väärin",
      addRowFailed: "Täytä kaikki rivin kentät",
      sendSuccess: "Lähetys onnistui",
      discountPercentageInvalid: "Alennus täytyy olla positiivinen",
      mustAddRows: "Laskussa ei ole rivejä",
      eInvoiceAddress: "Verkkolaskuosoite",
      noEinvoiceAddress: "Verkkolaskuosoitetta ei löytynyt. Laskua ei voitu lähettää.",
      noImageDefined: "Laskulle ei ole määritetty kuvaa.",
      noFactoringAgreementSelected: "Käytettävää siirtolauseketta ei ole valittu"
    },
    invoice: {
      processingDate: "Käsittelypäivä",
      partialAmount: "Osasuoritus",
      addPayment: "Lisää maksu",
      paidAmount: "Maksettu",
      paymentDate: "Maksupäivä",
      postingDate: "Kirjauspäivä",
      unpaidSum: "Summa",
      amountToPay: "Maksettava",
      approvalStatus: "Tarkastuksen tila",
      bankAccount: "Tilinumero",
      barcode: "Viivakoodi",
      createdDate: "Luotu",
      currency: "Valuutta",
      deliveryMethod: "Toimitustapa",
      details: "Tiedot",
      documentDate: "Laskun päivä",
      documentID: "Laskun numero",
      documentLink: "Data/XML",
      documentName: "Dokumentin nimi",
      dueDate: "Eräpäivä",
      imageName: "Kuvan nimi",
      imageLink: "Kuva",
      invoiceChannel: "Laskun toimitustapa",
      messageType: "Sanoman tyyppi",
      orderReference: "Tilausviite",
      paymentReference: "Pankkiviite",
      paymentStatus: "Maksun tila",
      receiverID: "Vastaanottajan tunnus",
      receiverName: "Vastaanottaja",
      receiverVAT: "Vastaanottajan VAT-tunnus",
      sellerReference: "Myyjän viite",
      senderName: "Laskuttaja",
      senderVAT: "Y-tunnus",
      storageKey: "Storage key",
      storageStatus: "Storage status",
      invoiceStatus: "Tila",
      senderMessageId: "Lähettäjän erätunniste"
    },
    paymentStatus: {
      PAID: "Maksettu",
      NOTPAID: "Maksamatta",
      "": "Kaikki"
    },
    invoiceStatus: {
      NEW: "Uusi",
      WAIT: "Odota",
      READY: "Valmis"
    },
    sellInvoice: {
      giveBankReference: "Syötä vähintään 4 numeroa laskun numeroon tai anna pankkiviite",
      //details
      details: "Lisätiedot",
      detailsName: "Maksun saaja",
      documentDate: "Laskun päivä",
      documentID: "Laskun numero",
      invoiceSum: "Yhteensä",
      documentName: "Dokumentin nimi",
      dueDate: "Eräpäivä",
      customReference: "Tilausviite",
      paymentReference: "Pankkiviite",
      // recipient
      recipient: "Ostaja",
      recipientName: "Nimi",
      receiverVAT: "Y-tunnus",
      recipientStreetAddress: "Osoite",
      recipientPostOffice: "Toimipaikka",
      recipientPostCode: "Postinumero",
      recipientCountry: "Maa",
      // sender
      sender: "Myyjä",
      senderName: "Nimi",
      senderVAT: "Y-tunnus",
      senderStreetAddress: "Osoite",
      senderPostOffice: "Toimipaikka",
      senderPostCode: "Postinumero",
      senderCountry: "Maa",

      product: "Tuote",
      numberOfItems: "kpl",
      individualPrice: "a-hinta",
      discountPercentage: "Ale %",
      totalTaxFree: "Yhteensä",
      VAT: "ALV",
      VATPercentage: "ALV %",
      VATAmount: "ALV yht",
      total: "Yhteensä",
      taxSummary: "Veroerittely",
      taxableAmount: "Verotettava summa",
      toBePaid: "Maksettava",

      add: "Lisää",
      addImage: "Lisää laskun kuva",
      closePreview: "Sulje esikatselu",
      "delete": "Tuhoa",
      invalidPayementReference: "Pankkiviite väärin",
      pdfTitle: "Lasku",
      showPreview: "Esikatsele(PDF)",

      factoring: {
        title: "Rahoitus",
        agreementTitle: "Siirtolauseke ja käytettävät tilit",
        select: "Valitse",
        change: "Vaihda",
        name: "Saajan nimi",
        bid: "Saajan y-tunnus"
      }
    },
    invoiceView: {
      upload: "Lisää liitteitä",
      administrate: "Hallinnoi",
      viewTitle: "Lasku",
      imageName: "Kuva",
      changePaymentStatus: "Muuta maksun tilaa",
      changeInvoiceStatus: "Muuta laskun tilaa",
      paymentStatus: "Maksun tila",
      invoiceStatus: "Laskun tila",
      paidTooltip: "Merkitse lasku maksetuksi(PAID)",
      notPaidTooltip: "Merkiset lasku maksamattomaksi (NOT PAID)",
      readyTooltip: "Vaptauta lasku haettavaksi(READY)",
      waitTooltip: "Pysäytä lasku tarkastusta varten (WAIT)",
      newTooltip: "Aseta lasku uudeksi (NEW)"
    },
    storageView: {
      viewTitle: "Liite",
      imageName: "Kuva",
      files: "Tiedostot",
      downloadAll: "Lataa kaikki",
      urlError: "Virhe tietovaraston URL-osoitteessa, tarkistakaa linkki ja yrittäkää uudelleen."
    },

    comment: {
      addComment: "Lisää kommentti",
      thumbsUp: "Yay!",
      thumbsIdle: "Meh",
      thumbsDown: "morturi te salutant",
      editComment: "Muokkaa",
      cancel: "Peru",
      edited: "Muokannut"
    },
    email: {
      add: "Lisää",
      send: "Lähetä sähköposti",
      checkerSubject: "Asiatarkista lasku",
      accountingSubject: "Ostolasku(t)",
      noRecipient: "Virhe, lisää vähintää yksi vastaanottaja",
      customEmailHeader: "Sähköposti",
      customSubject: "Sähköpostin lähettäjä ",
      noComments: "Ei kommentteja",
      invalidRecipient: "Sähköpostiosoite ei ole kelvollinen"
    },
    footer: {
      company: "Apix Messaging Oy",
      address: "Sinikalliontie 7, 02630 Espoo",
      infoGuidebook: "Ohjekirja",
      vatNumber: "Y-tunnus: 2332748-7",
      customerService: "Asiakastuki",
      serviceEmail: "servicedesk@apix.fi"
    },
    tooltip: {
      checker: "Lasku on lähetetty tarkastettavaksi",
      dueSoon: "Laskun eräpäivä on lähellä",
      negative: "Laskun tarkastuksen tila on negatiivinen",
      neutral: "Laskun tarkastuksen tila on neutraali",
      pastDue: "Laskun eräpäivä on jo mennyt",
      positive: "Laskun tarkastuksen tila on positiivinen",
      retrieved: "Lasku on haettu",
      sentToBookkeeping: "Lasku on lähetetty kirjanpitäjälle",
      wait: "Lasku on odotustilassa"
    },
    registration: {
      common: {
        back: "Takaisin",
        error: "Tunnistamaton virhe",
        registrationHeader: "Rekisteröinti"
      },
      footer: {
        company: "Apix Messaging Oy",
        address: "Sinikalliontie 7, 02630 Espoo",
        infoGuidebook: "Ohjekirja",
        businessId: "Y-tunnus: 2332748-7",
        customerService: "Asiakastuki",
        serviceEmail: "servicedesk@apix.fi"
      },
      form: {
        acceptContract: "Hyväksy sopimus",
        addContractFailed: "Sopimuksen luominen epäonnistui: ",
        apiError: "Yhteys palveluun epäonnistui.",
        basicInfo: "Syötä perustiedot",
        confirm: "Vahvista sopimuksen hyväksyminen",
        contractsCreated: "Sopimus luotu onnistuneesti.",
        country: {
          FI: "Suomi",
          GB: "Englanti",
          LV: "Latvia",
          LT: "Liettua",
          NO: "Norja",
          PL: "Puola",
          SE: "Ruotsi",
          DE: "Saksa",
          DK: "Tanska",
          EE: "Viro"
        },
        createCustomerFailed: "Asiakkuuden perustaminen epäonnistui. Ota yhteyttä asiakaspalveluun. Virhe: ",
        emailListPlaceholder: "sähköposti@osoite.fi",
        invalidBusinessId: "Y-tunnuksen tulee olla oikea y-tunnus joko muotoa FIXXXXXXXX tai XXXXXXX-X",
        invalidNotifyEmail: "Lisätiedon tulee olla sähköpostiosoitelista, eroteltuna puolipisteillä",
        invalidUsername: "Sähköpostin pitää olla oikea sähköpostiosoite (esim. nimi@palvelu.fi)",
        language: {
          de_DE: "Saksa",
          en_US: "Englanti",
          fi_FI: "Suomi",
          sv_SE: "Ruotsi"
        },
        missingMandatory: "Pakollinen kenttä puuttuu:",
        parameterMandatory: "Pakollinen lisätieto puuttuu:",
        passwordMismatch: "Annetut salasanat eroavat toisistaan",
        passwordTooShort: "Salasanan tulee olla vähintään 4 merkkiä.",
        queryBusinessId: "Hae",
        queryBusinessIdHelp: "(Muoto: xxxxxxx-y)",
        selectCountry: "Valitse maa",
        selectLanguage: "Valitse kieli",
        serviceMandatory: "Joko 'Lähetä tai 'Vastaanota' tulee olla valittuna.",
        serviceSelection: "Valitse palvelut",
        softwareName: "Laskutusohjelmisto",
        softwareVersion: "Ohjelmistoversio",
        usernameHelp: "(Sähköpostiosoite on tunnuksesi hallinnoidessasi Apix:in palveluita)",
        verifyForm: "Valitse palvelut",
        verifyFormFailed: "Rekisteröintitiedoissa puutteita.",
        websiteHelp: "(Eg.: www.apix.fi)"
      },
      formfields: {
        businessId: "Y-tunnus",
        companyName: "Yrityksen virallinen nimi",
        additionalName: "Aputoiminimet",
        streetAddress1: "Katuosoite 1",
        streetAddress2: "Katuosoite 2",
        postCode: "Postinumero",
        postOffice: "Postitoimipaikka",
        country: "Maa",
        contactPerson: "Yhteyshenkilö",
        phoneNumber: "Puhelinnumero",
        language: "Kieli",
        website: "WWW",
        username: "Sähköposti",
        password: "Salasana",
        passwordVerification: "Vahvista salasana"
      },
      info: {
        firstMessage: "",
        secondMessage: "Rekisteröityminen käyttämään Apix Messaging Oy:n palveluita tapahtuu kahdessa vaiheessa.",
        thirdMessage: "Ensin täytät yrityksestäsi perustiedot ja valitset itsellesi tunnuksen palveluiden hallintaan. Valitset myös tunnukselle itse salasanan, jonka pituus on vähintään 4 merkkiä. Syötettyäsi perustiedot, valitset palvelut, joita haluat käyttää.",
        fourthMessage: "Hyväksyttyäsi sopimuksen järjestelmä näyttää yhteenvedon.",
        fifthMessage: "Prosessin päätyttyä voit muokata valitsemiasi palveluita, yhteystietojasi ja vaihtaa salasanasi osoitteessa: webshop.apix.fi. Palveluun kirjautumiseen käytät prosessissa luomiasi tunnusta (sähköpostiosoite) ja salasanaa.",
        linkToForm: "Aloita"
      },

      laskumappi: {
        firstMessage: "Rekisteröityminen käyttämään Apix Messaging Oy:n palveluita tapahtuu seuraavasti:",
        secondMessage: "1. Anna yrityksesi perustiedot.",
        thirdMessage: "2. Luo tunnus ja salasana.",
        fourthMessage: "3. Valitse käytettävät palvelut.",
        fifthMessage: "Rekisteröitymisen jälkeen kirjaudu palveluun käyttämällä luomaasi tunnusta (sähköpostiosoite ja salasana).",

        webshopMessage: "Voit muokata palvelujasi, yhteystietojasi tai salasanaasi rekisteröitymisen jälkeen",
        hinnastoMessage: "Apixin hinnasto löytyy",
        here: "täältä",
        linkToForm: "Aloita rekisteröinti",
        registrationHeader: "Ota Laskumappi käyttöön"
      },

      modal: {
        ok: "Ok",
        cancel: "Peru"
      },
      receipt: {
        address: "Osoite",
        dataError: "Datan siirrossa tapahtui virhe.",
        info: "Perustiedot",
        printReceipt: "Tulosta",
        ready: "Valmis",
        serviceContract: "Palvelusopimus",
        serviceContractFirstText: "Tämä on palvelusopimus", // Apix Messaging Oy:n; y-tunnus 2332748-7 ja ",
        prepend: ":n; y-tunnus",
        and: "ja",
        serviceContractSecondText: " välillä. Sopimuksessa olevien palveluiden tarkemmat kuvaukset ja ehdot löytyvät osoitteesta:",
        services: "Sopimuksen palvelut",
        startUsingService: "Voit aloittaa palvelun käytön heti.",
        here: "täältä"
      },
      service: {
        categories: {
          archive: "Arkistoi kaikki lähtevät ja saapuvat sanomat",
          achiveHelpText: "",
          receive: "Vastaanota",
          receiveHelpText: "",
          send: "Lähetä",
          sendHelpText: "Lähetä palvelu",
          storagefilesystem: "Arkistoi tiedostoja. Hinta 0,84 merkkiä/tiedosto (max. koko 50 mt).",
          sendToCollector: "Siirrä laskuja perintään arkistosta"
        },
        receive: {
          arrivalNotification: "Ilmoitus uusista saapuneista laskuista",
          arrivalNotificationHelpText: "",
          expiryNotification: "Ilmoitus erääntyvistä laskuista",
          expiryNotificationHelpText: "",
          edi: "Haluan vastaanotaa EDI-sanomia, ottakaa minuun yhteyttä.",
          ediHelpText: "",
          scanning: "Skannaus",
          scanningHelpText: "",
          emailScan: "Sähköpostiskannaus, aktivointi kestää 2-3 arkipäivää.",
          emailScanHelpText: ""
        },
        send: {
          priority: "Palvelu paperilaskujen lähettämisestä Priority-luokassa 2. luokan sijaan",
          priorityHelpText: "",
          consumerInvoicing: "Kuluttajalaskutus verkkopankkeihin, ottakaa minuun yhteyttä.",
          consumerInvoicingHelpText: "",
          edi: "Haluan lähettää EDI-sanomia, ottakaa minuun yhteyttä.",
          ediHelpText: "",
          accounting: "Palvelu kirjanpitokopion lähettämisestä kirjanpitäjälle sähköpostitse. Hinta 0,15 merkkiä/lähetys tai asiakaskohtaisen hinnaston mukaan",
          accountingHelpText: "",
          duplicateCheck: "Palvelu saman laskun lähetyksen estämiseksi useaan kertaan",
          duplicateCheckHelpText: "",
          debtCollect: "Laskujen maksuvalvonta, ottakaa minuun yhteyttä.",
          debtCollectHelpText: "",
          sendToCollector: "Siirrä laskuja perintään",
          factoringCopy: "Laskujen rahoitus, Ottakaa minuun yhteyttä.",
          factoringCopyHelpText: "",
          factoringCopyAll: "Factoring-kopio kaikista laskuista rahoitusyhtiölle, ottakaa minuun yhteyttä",
          factoringCopyAllHelpText: ""
        }
      },
      softwareNames: {
        other: "Jokin muu"
      }
    },
    pricing: {
      services: "Palvelut",
      stamps: "Verkkopostimerkki"
    },
    passwordreset: {
      send: "Lähetä",
      description: "Järjestelmä lähettää käyttäjälle kuuluvaan sähköpostiin linkin salasanan vaihtamista varten.",
      invalidlink: "Linkki on vanhentunut.",
      invalidPasswords: "Salasanat eivät täsmää tai pituus on alle 4 merkkiä",
      here: "Pyydä uusi linkki",
      sent: "Linkki lähetetty",
      min4chars: "- vähintään 4 merkkiä"
    }
  };
});